import { Badge, Col, Row, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import hasFeature from "utils/dataProviderFeatures";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";

import Footer from "../footer/Footer.js";
import Nav from "../nav/Nav.js";
import Admin from "./Admin.js";
import AdminDataProvider from "./AdminDataProvider";
import Custom from "./Custom";
import ExcludeSettings from "./ExcludeSettings.js";
import GeneralSettings from "./GeneralSettings";
import AnalysisConfigTabs from "./analysisConfig/AnalysisConfigTabs";
import AdminHorus from "./dataProvider/AdminHorus";
import AdminInqom from "./dataProvider/AdminInqom";
import AdminIDepot from "./ged/AdminIDepot";
import AdminIDocus from "./ged/AdminIDocus";
import AdminMCF from "./ged/AdminMCF";



import { EFeatures } from "enums/EFeatures";
import logAction from "utils/logActions";
import { hasFeatures } from "utils/security";
import { TimeMeasurement } from "utils/timeMeasurement";
import "./Admin.css";
import AdminConciliator from "./ged/AdminConciliator";
import Roles from "./roles/Roles";

const { TabPane } = Tabs;

function AdminTabs(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [activeKey, setActiveKey] = useState(
    hasFeatures([EFeatures.MANAGE_USERS_AND_RIGHTS]) ? "1" : "2"
  );
  const [updateAdmin, setUpdateAdmin] = useState(false);
  const [updateRoles, setUpdateRoles] = useState(false);

  const timeMeasurement = new TimeMeasurement();

  const activeKeyRef = useRef(activeKey);

  const onChange = (activeKey) => {
    setActiveKey((previous) => {
      if (activeKey === "6") timeMeasurement.start();
      if (previous === "6") logAction(206, timeMeasurement.getElapsedTime());
      activeKeyRef.current = activeKey;
      return activeKey;
    });
  };

  useEffect(() => {
    return () => {
      if (activeKeyRef.current === "6") logAction(206, timeMeasurement.getElapsedTime());
    };
  }, []);

  return (
    <div className="admin">
      <Nav {...props} />
      <Row justify="center" className="admin-content">
        <Col xs={22} sm={22} md={22} lg={22} xl={22} className="admin-box">
          <Tabs defaultActiveKey={activeKey} onChange={onChange} activeKey={activeKey}>
            {hasFeatures([EFeatures.MANAGE_USERS_AND_RIGHTS]) && (
              <TabPane
                className="tab-container"
                tab={
                  hasFeature(
                    accountingFirm.fec,
                    accountingFirm.dataProvider,
                    "CONNECTED_RIGHTS"
                  ) ? (
                    <Badge
                      count={"Nouveau"}
                      color="#4569f8"
                      size="small"
                      style={{
                        fontSize: 8,
                        top: "-10px",
                      }}
                    >
                      Gestion des utilisateurs
                    </Badge>
                  ) : (
                    "Gestion des utilisateurs"
                  )
                }
                key="1"
              >
                <Admin
                  updateAdmin={updateAdmin}
                  setUpdateAdmin={setUpdateAdmin}
                  updateRoles={updateRoles}
                  setUpdateRoles={setUpdateRoles}
                />
                <Roles
                  updateAdmin={updateAdmin}
                  setUpdateAdmin={setUpdateAdmin}
                  updateRoles={updateRoles}
                  setUpdateRoles={setUpdateRoles}
                />
              </TabPane>
            )}
            {hasFeatures([EFeatures.ADMINISTRATION]) && (
              <>
                {" "}
                {accountingFirm.mcf ? (
                  <TabPane className="tab-container" tab={<span>Connexion à MCF</span>} key="2">
                    <AdminMCF />
                  </TabPane>
                ) : null}
                {accountingFirm.dataProvider === "HORUS" && accountingFirm.fec === false ? (
                  <TabPane
                    className="tab-container"
                    tab={
                      <span>Connexion à Horus</span>
                    }
                    key="3"
                  >
                    <AdminHorus />
                  </TabPane>
                ) : null}
                {accountingFirm.dataProvider === "INQOM" && accountingFirm.fec === false ? (
                  <TabPane
                    className="tab-container"
                    tab={
                      <span>Connexion à Inqom</span>
                    }
                    key="3"
                  >
                    <AdminInqom />
                  </TabPane>
                ) : null}
                {accountingFirm.dataProvider === "ACD" && accountingFirm.fec === false ? (
                  <TabPane
                    className="tab-container"
                    tab={
                      <Badge count={"BETA"} color="cyan" size={"small"} style={{ top: "-8px" }}>
                        <span>Connexion à I-Dépôt</span>
                      </Badge>
                    }
                    key="3"
                  >
                    <AdminIDepot />
                  </TabPane>
                ) : null}
                {accountingFirm.iDocus ? (
                  <TabPane
                    className="tab-container"
                    tab={
                      <Badge
                        count={"Nouveau"}
                        color="#4569f8"
                        size="small"
                        style={{
                          fontSize: 8,
                          top: "-10px",
                        }}
                      >
                        <span>Connexion à iDocus</span>
                      </Badge>
                    }
                    key="4"
                  >
                    <AdminIDocus />
                  </TabPane>
                ) : null}
                {accountingFirm.conciliator ? (
                  <TabPane
                    className="tab-container"
                    tab={
                      <Badge
                        count={"Nouveau"}
                        color="#4569f8"
                        size="small"
                        style={{
                          fontSize: 8,
                          top: "-10px",
                        }}
                      >
                        <span>Connexion à Conciliator</span>
                      </Badge>
                    }
                    key="5"
                  >
                    <AdminConciliator />
                  </TabPane>
                ) : null}
                {hasFeature(
                  accountingFirm.fec,
                  accountingFirm.dataProvider,
                  "ADMIN_CREDENTIALS"
                ) ? (
                  <TabPane
                    className="tab-container"
                    tab={<span>Connexion {accountingFirm.dataProvider}</span>}
                    key="6"
                  >
                    <AdminDataProvider {...props} />
                  </TabPane>
                ) : null}
                <TabPane
                  className="tab-container"
                  key="7"
                  tab={
                    !accountingFirm.proAbonoFeatures ? null : !accountingFirm.proAbonoFeatures?.includes(
                        "analysis_config"
                      ) ? (
                      <Badge
                        count={"Pro"}
                        color="gold"
                        style={{
                          top: "-8px",
                          visibility: !accountingFirm.proAbonoFeatures
                            ? "hidden"
                            : !accountingFirm.proAbonoFeatures?.includes("analysis_config")
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        Paramétrage des comptes
                      </Badge>
                    ) : (
                      <Badge
                        count={"Nouveau"}
                        color="#4569f8"
                        size="small"
                        style={{
                          fontSize: 8,
                          top: "-10px",
                        }}
                      >
                        Paramétrage des comptes
                      </Badge>
                    )
                  }
                >
                  <AnalysisConfigTabs />
                </TabPane>
                <TabPane className="tab-container" key="8" tab={<span>Personnalisation</span>}>
                  <Custom />
                </TabPane>
                <TabPane className="tab-container" tab={<span>Mail to Box</span>} key="9">
                  <GeneralSettings />
                </TabPane>
                {hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CLIENT_IMPORT") ? (
                  <TabPane className="tab-container" tab={<span>Dossiers à exclure</span>} key="10">
                    <ExcludeSettings {...props} />
                  </TabPane>
                ) : null}
              </>
            )}
          </Tabs>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default AdminTabs;
