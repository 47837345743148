import { ArrowRightOutlined, InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, Row, Steps, Switch, Tooltip, Upload } from "antd";
import fr from "antd/es/date-picker/locale/fr_FR";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as XLSX from "xlsx";
import * as API from "../../api/API";
import HumanLostConnection from "../../assets/images/NoConnection.png";
import { selectClient } from "../../slices/clientSlice";
import ClientForecastTable from "./ClientForecastTable";
import "./ClientSettings.css";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

const { Dragger } = Upload;

const monthsRCA = [
  "Jan",
  "Fév",
  "Mar",
  "Avr",
  "Mai",
  "Jun",
  "Jul",
  "Aoû",
  "Sep",
  "Oct",
  "Nov",
  "Déc",
];

const defaultIndicsName = [
  "ca",
  "marge",
  "masse_sal",
  "treso",
  "compte_courant_associes",
  "charges_ext",
  "result",
];

const formatDateRCA = (dateString) => {
  const month = dateString.split(" ")[0];
  return monthsRCA[month - 1] + " " + dateString.split(" ")[1];
};

function createDateRange(startDate, endDate) {
  var dates = [];
  var currentDate = new Date(startDate);
  var lastDate = new Date(endDate);

  while (currentDate <= lastDate) {
    dates.push({
      date: `${dayjs(currentDate).year()}-${dayjs(currentDate).format("MM")}-01T00:00:00.000Z`,
    });
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dates;
}

function ClientForecast(props) {
  const clientId = props.match.params.clientId;
  const client = useSelector(selectClient);

  const [indicators, setIndicators] = useState({});
  const [showForecastTable, setShowForecastTable] = useState(false);
  const [settings, setSettings] = useState({});
  const [showRCAUpload, setShowRCAUpload] = useState(false);
  const [uploadedRCAFile, setUploadedRCAFile] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isForecastActivated, setIsForecastActivated] = useState(false);
  const [current, setCurrent] = useState(0);
  const [customIndicators, setCustomIndicators] = useState();
  const [monthDiff, setMonthDiff] = useState(0);

  const [form] = Form.useForm();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const onChangeStartDate = (date, dateString) => {
    const dateFormat = dayjs(date, "YYYY-MM-DD");
    setStartDate(dateFormat);
  };

  const onChangeEndDate = (date, dateString) => {
    const dateFormat = dayjs(date, "YYYY-MM-DD");
    setEndDate(dateFormat);
  };

  const onChangeRcaStartDate = (date, dateString) => {
    const dateFormat = dayjs(date, "YYYY-MM-DD");
    setStartDate(dateFormat);
    //setRcaStartDate(dateFormat);
  };

  const onChangeRcaEndDate = (date, dateString) => {
    const dateFormat = dayjs(date, "YYYY-MM-DD");
    setEndDate(dateFormat);
    //setRcaEndDate(dateFormat);
  };

  useEffect(() => {
    if (client.status === "ready") {
      setStartDate(
        client.forecast?.settings?.start_month + 1 && client.forecast?.settings?.start_year
          ? dayjs(
              `${client.forecast?.settings?.start_year}-${(
                client?.forecast?.settings?.start_month + 1
              )
                .toString()
                .padStart(2, "0")}-01`
            )
          : dayjs(client?.client?.date_debut_exercice)
      );

      setEndDate(
        client.forecast?.settings?.end_month && client.forecast?.settings?.fiscal_year
          ? dayjs(
              `${client.forecast?.settings?.fiscal_year}-${(
                client?.forecast?.settings?.end_month + 1
              )
                .toString()
                .padStart(2, "0")}-01`
            )
          : dayjs(client?.client?.date_debut_exercice).add(1, "year").subtract(1, "month")
      );
    }
  }, []);

  useEffect(() => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const diff = end.diff(start, "month") + 1;
    setMonthDiff(diff);
  }, [startDate, endDate, client]);

  useEffect(() => {
    (async () => {
      if (client.status === "ready") {
        let loadedIndicators = await getMyCustomIndicators();
        setCustomIndicators(loadedIndicators);
        if (client.forecast?.settings) {
          if (
            client.forecast?.settings?.active?.templates &&
            client.forecast?.settings?.percent?.templates
          ) {
            const newSettings = {
              active: {
                global: client.forecast.settings.active.global,
                ...client.forecast.settings.active,
                // check if the indicator is empty (default template) or not.
                // If it's empty, we don't add in any active setting
                // If it's not empty, we add in the active settings
                ...(loadedIndicators.length > 0
                  ? loadedIndicators?.reduce((acc, current) => {
                      // Find the different settings (settings added / removed ) in the template compared to what's in the client.forecast
                      let indicActive = client.forecast.settings.active.templates?.find(
                        (indic) => indic.id === current.id
                      );
                      // If there is a difference, we add the indicator to the active settings
                      acc = { ...acc, [current.id]: indicActive ? indicActive.value : true };
                      return acc;
                    }, {})
                  : {}),
              },
              percent: {
                ...client.forecast.settings.percent,
                // Same as the active settings here
                ...(loadedIndicators.length > 0
                  ? loadedIndicators?.reduce((acc, current) => {
                      let indicPercent = client.forecast.settings.percent.templates?.find(
                        (indic) => indic.id === current.id
                      );
                      acc = { ...acc, [current.id]: indicPercent ? indicPercent.value : 0 };
                      return acc;
                    }, {})
                  : {}),
              },
              fiscal_year: client.forecast.settings.fiscal_year,
              start_month: client.forecast.settings.start_month,
              start_year: client.forecast.settings.start_year,
              end_month: client.forecast.settings.end_month,
            };
            delete newSettings.active.templates;
            delete newSettings.percent.templates;
            setSettings(newSettings);
          } else {
            setSettings(client.forecast.settings);
          }
        } else {
          // If the client.forecast does not exit, set every settings to the default ones
          setSettings({
            active: {
              ...defaultIndicsName.reduce((acc, current) => {
                acc[current] = true;
                return acc;
              }, {}),
            },
            percent: {
              ...defaultIndicsName.reduce((acc, current) => {
                acc[current] = 0;
                return acc;
              }, {}),
            },
          });
        }

        const indicContent = createNewIndicatorContent();
        const forecastIndics = client.forecast
          ? {
              ...client.forecast,
              // Similarly to the active and percent settings, check if the client.forecast exit or not (i.e. default template or not)
              ...(loadedIndicators.length > 0
                ? loadedIndicators?.reduce((acc, current) => {
                    // Find if there are different indicators (indicators added / removed ) in the template compared to what's in the client.forecast
                    const indic = client.forecast.templates?.find(
                      (indic) => indic.id === current.id
                    );
                    // If there are new indicators not present in the client.forecast, we add them with the default values
                    acc = {
                      ...acc,
                      [current.id]: indic ? indic.content : [...indicContent],
                    };
                    return acc;
                  }, {})
                : {}),
            }
          : // If the client.forecast does not exit, set every indicators to the default ones
            defaultIndicsName.reduce((acc, current) => {
              acc[current] = [...indicContent];
              return acc;
            }, {});

        delete forecastIndics?.templates;
        delete forecastIndics?.settings;
        setIndicators(forecastIndics);

        if (client.forecast) {
          if (client.forecast?.settings?.active?.global) {
            setShowForecastTable(true);
            setIsForecastActivated(true);
          } else {
            if (Object.keys(forecastIndics).length > 0) setShowForecastTable(true);
          }
        } else setShowForecastTable(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getCustomIndicatorsInFormat = () => {
    if (!customIndicators) return [];
    const indicContent = createNewIndicatorContent();
    return Object.keys(customIndicators).length > 0
      ? customIndicators.reduce(
          (acc, current) => ({
            ...acc,
            [current.id]: [...indicContent],
          }),
          {}
        )
      : [];
  };

  const loadWithPastYear = async () => {
    const res = await API.getIndicators(clientId);
    if (res.status === 200) {
      let result = await res.json();

      let pastYearStartDate = dayjs(startDate).subtract(1, "year");
      let pastYearEndDate = dayjs(endDate).subtract(1, "year");

      for (let key in result) {
        let array = result[key];

        let filteredArray = array.filter((item) => {
          let itemDate = dayjs(item.date.slice(0, 10));
          return (
            itemDate.isSameOrAfter(pastYearStartDate) && itemDate.isSameOrBefore(pastYearEndDate)
          );
        });

        filteredArray.sort((a, b) => b.date.localeCompare(a.date));

        filteredArray.forEach((item) => {
          const currentDate = dayjs(item.date).add(1, "year").toISOString();
          item.date = currentDate;
        });

        result[key] = filteredArray;
      }

      // Adding custom indicators and the last default indicator which doesn't seem to exist in the databse
      result = {
        ...result,
        compte_courant_associes: createNewIndicatorContent(),
        ...getCustomIndicatorsInFormat(),
      };

      setIndicators(result);
      setShowForecastTable(true);
      logAction(257, 1, client._id);
    } else {
      console.log("Error getIndicators");
    }
  };

  const getMyCustomIndicators = async () => {
    const templateId = client.templates?.analysis;
    switch (templateId) {
      case "default":
        const templates = await API.getAnalysisConfigTemplates().then((res) => res.json());
        const indicators = templates
          ?.find((elt) => elt.type === "ANALYSIS" && elt.default === true)
          ?.templateAnalysis.filter((indicator) => indicator.id && indicator.name);
        if (indicators) return indicators;
        else return {};
      case "sesha":
        return {};
      case undefined:
        return {};
      default:
        const template = await API.getAnalysisConfigTemplateById(templateId).then((res) =>
          res.json()
        );
        const templateIndicators = template.templateAnalysis.filter(
          (indicator) => indicator.id && indicator.name
        );
        return templateIndicators;
    }
  };

  const createNewIndicatorContent = () => {
    const endMonth = dayjs(endDate).month();
    const year = dayjs(endDate).year();
    const indicatorContent = [];
    for (let i = 0; i < monthDiff; i++) {
      indicatorContent.push({
        date: dayjs(year + "-" + endMonth + "-01")
          .utc()
          .subtract(i, "month")
          .startOf("month")
          .toISOString(),
        value: 0,
      });
    }

    return indicatorContent.reverse();
  };

  const loadCustomIndicators = async () => {
    const templateId = client.templates?.analysis;

    if (templateId === "default") {
      return {};
    }
    const template = await API.getAnalysisConfigTemplateById(templateId).then((res) => res.json());
    return template.templateAnalysis.filter((el) => el.name);
  };

  const loadEmpty = async () => {
    const customIndicators = await loadCustomIndicators();

    var dateRange = createDateRange(startDate, endDate);

    // customIndicators can be empty (if the template used is the default one), thus we need to check if it's empty or not
    let allIndicators = [
      ...defaultIndicsName,
      ...(Object.keys(customIndicators).length > 0 ? customIndicators.map((el) => el.id) : []),
    ];

    const forecastData = Object.fromEntries(allIndicators.map((el) => [el, dateRange]));

    setSettings({
      active: Object.fromEntries(allIndicators.map((el) => [el, true])),
      percent: Object.fromEntries(allIndicators.map((el) => [el, 0])),
    });
    setIndicators(forecastData);
    setShowForecastTable(true);
    logAction(258, 1, client._id);
  };

  const loadRCAFile = (file) => {
    console.log("RCA uploaded");
    setUploadedRCAFile(file);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      // Accédez aux données de la feuille Budget
      const worksheetBudget = workbook.Sheets["Budget"];
      const jsonDataBudget = XLSX.utils.sheet_to_json(worksheetBudget, { header: 1 });

      // Accédez aux données de la feuille Tréso
      const worksheetTreso = workbook.Sheets["Trésorerie"];
      const jsonDataTreso = XLSX.utils.sheet_to_json(worksheetTreso, { header: 1 });

      let tableEndDate = endDate;
      let tableStartDate = startDate;

      var dateRange = createDateRange(tableStartDate, tableEndDate);

      //deep copy du array dateRange pour modification
      let forecastData = {
        ca: JSON.parse(JSON.stringify(dateRange)),
        marge: JSON.parse(JSON.stringify(dateRange)),
        masse_sal: JSON.parse(JSON.stringify(dateRange)),
        treso: JSON.parse(JSON.stringify(dateRange)),
        compte_courant_associes: JSON.parse(JSON.stringify(dateRange)),
        charges_ext: JSON.parse(JSON.stringify(dateRange)),
        result: JSON.parse(JSON.stringify(dateRange)),
      };

      const caIndex = jsonDataBudget.indexOf(
        jsonDataBudget.find((row) => row[0].toLowerCase() === "chiffre d'affaires")
      );
      const margeIndex = jsonDataBudget.indexOf(
        jsonDataBudget.find((row) => row[0].toLowerCase() === "marge globale")
      );
      const masse_salIndex = jsonDataBudget.indexOf(
        jsonDataBudget.find((row) => row[0].toLowerCase() === "charges de personnel (total)")
      );
      const tresoIndex = jsonDataTreso.indexOf(
        jsonDataTreso.find((row) => row[1]?.toLowerCase() === "solde de trésorerie")
      );
      const compte_courant_associesIndex = jsonDataTreso.indexOf(
        jsonDataTreso.find((row) => row[1]?.toLowerCase() === "apports en comptes courants")
      );
      const charges_extIndex = jsonDataBudget.indexOf(
        jsonDataBudget.find((row) => row[0].toLowerCase() === "charges externes (total)")
      );
      const resultIndex = jsonDataBudget.indexOf(
        jsonDataBudget.find((row) => row[0].toLowerCase() === "résultat de l'exercice")
      );

      for (var i = 0; i < forecastData.ca.length; i++) {
        const dateIndexBudget = jsonDataBudget[0].indexOf(
          formatDateRCA(dayjs(forecastData.ca[i].date).format("M YYYY"))
        );
        const dateIndexTreso = jsonDataTreso[0].indexOf(
          formatDateRCA(dayjs(forecastData.ca[i].date).format("M YYYY"))
        );
        forecastData.ca[i].value = jsonDataBudget[caIndex]?.[dateIndexBudget] || 0;
        forecastData.marge[i].value = jsonDataBudget[margeIndex]?.[dateIndexBudget] || 0;
        forecastData.masse_sal[i].value = jsonDataBudget[masse_salIndex]?.[dateIndexBudget] || 0;
        forecastData.treso[i].value = jsonDataTreso[tresoIndex]?.[dateIndexTreso] || 0;
        forecastData.compte_courant_associes[i].value =
          jsonDataTreso[compte_courant_associesIndex]?.[dateIndexTreso] || 0;
        forecastData.charges_ext[i].value =
          jsonDataBudget[charges_extIndex]?.[dateIndexBudget] || 0;

        forecastData.result[i].value = jsonDataBudget[resultIndex]?.[dateIndexBudget] || 0;
      }
      // Adding custom indicators
      forecastData = {
        ...forecastData,
        ...getCustomIndicatorsInFormat(),
      };

      setIndicators(forecastData);
      setShowForecastTable(true);
      setShowRCAUpload(false);
      logAction(256, 1, client._id);
    };

    reader.readAsArrayBuffer(file);
  };

  const btn_select = (
    <>
      <Tooltip
        placement="topRight"
        title="Dans RCA, veuillez sélectionner le “Mode développé” puis l’exporter en xls."
      >
        <Button
          onClick={(e) => {
            setShowRCAUpload(true);
            handleCancel();
          }}
          className="call-action-btn data-origin-btn"
        >
          Charger un export de budget RCA <ArrowRightOutlined />
        </Button>
      </Tooltip>
      <Button
        onClick={(e) => {
          loadWithPastYear();
          handleCancel();
        }}
        className="call-action-btn data-origin-btn"
      >
        Utiliser les données n-1 <ArrowRightOutlined />
      </Button>
      <Button
        onClick={(e) => {
          loadEmpty();
          handleCancel();
        }}
        className="call-action-btn data-origin-btn"
      >
        Renseigner les données manuellement <ArrowRightOutlined />
      </Button>
    </>
  );

  const onFinish = async (values) => {
    if (client.forecast?.settings?.active?.global !== isForecastActivated) {
      isForecastActivated ? logAction(267, 1, client._id) : logAction(268, 1, client._id);
    }

    let res = await API.putForecast(clientId, {
      ...indicators,
      settings: {
        active: {
          ...settings.active,
          global: isForecastActivated,
        },
        percent: {
          ...settings.percent,
        },
        start_month: startDate?.$M,
        start_year: startDate?.$y,
        end_month: endDate?.$M,
        fiscal_year: endDate?.$y,
      },
    });

    if (res.status === 201) {
      // log kpi utilisation prévisionnel
      window.location.reload();
    }
  };

  const steps = [
    {
      title: "Paramétrage",
      content: (
        <>
          <div className="year-select-forecast">
            <p style={{ fontWeight: "bold" }}>Interval analysé :</p>
            <div>
              <DatePicker
                locale={{
                  ...fr,
                  lang: {
                    ...fr.lang,
                  },
                }}
                format={"MMMM YYYY"}
                style={{ borderRadius: "30px", width: "150px" }}
                allowClear={false}
                onChange={onChangeRcaStartDate}
                defaultValue={startDate}
                status={dayjs(endDate).isSameOrBefore(startDate, "month") ? "error" : null}
                picker="month"
                placeholder="Sélectionner une date"
              />
              <span style={{ padding: "0 10px 0 10px" }}>à</span>
              <DatePicker
                locale={{
                  ...fr,
                  lang: {
                    ...fr.lang,
                  },
                }}
                format={"MMMM YYYY"}
                style={{ borderRadius: "30px", width: "150px" }}
                allowClear={false}
                onChange={onChangeRcaEndDate}
                defaultValue={endDate}
                status={dayjs(endDate).isSameOrBefore(startDate, "month") ? "error" : null}
                picker="month"
                placeholder="Sélectionner une date"
              />
              {monthDiff && monthDiff > 0 && monthDiff < 24 ? (
                <span>
                  <span style={{ padding: "0 10px 0 10px" }}>-</span>Exercice de :{" "}
                  <b style={{ color: "blue" }}>{monthDiff} mois</b>
                </span>
              ) : null}
              {monthDiff <= 1 ? (
                <div className="rca-error-msg">
                  <InfoCircleOutlined /> La date de début doit être antérieure à la date de fin.
                </div>
              ) : null}
              {monthDiff && monthDiff > 23 ? (
                <div className="rca-error-msg">
                  <InfoCircleOutlined /> La période ne doit pas dépasser 23 mois
                </div>
              ) : monthDiff === undefined ? (
                <></>
              ) : null}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Import",
      content: (
        <Dragger
          name="fileRCA"
          method="get"
          className="upload-rca"
          maxCount={1}
          forceRender
          onChange={(info) => {
            const { status } = info.file;
            if (status !== "uploading") {
              console.log(`${info.file.name} file uploading.`);
            }
            if (status === "done") {
              console.log(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
              console.log(`${info.file.name} file upload failed.`);
            }
          }}
          onRemove={(file) => {
            console.log("Remove file", file);
            setUploadedRCAFile({});
          }}
          beforeUpload={loadRCAFile}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Cliquer ou déposer votre fichier</p>
        </Dragger>
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  useEffect(() => form.resetFields(), [client]);

  useEffect(() => {
    if (client.status === "ready") {
      form.setFieldsValue({
        startDate,
        endDate,
      });
    }
  }, [form, startDate, endDate]);

  return (
    <div className="forecast">
      <Form
        form={form}
        onFinish={onFinish}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
        initialValues={{
          startDate: startDate,
          endDate: endDate,
          switchForecast: isForecastActivated,
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row className="import-submit-row">
              <Button
                type="button"
                className="other-action-btn return-button-fec pos-btn-fec"
                onClick={() => {
                  props.activeKeyImport("1");
                  props.importActive(false);
                }}
              >
                Annuler
              </Button>
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="call-action-btn link-button-fec pos-btn-fec"
                  disabled={monthDiff > 23 || monthDiff <= 0}
                  style={monthDiff > 23 || monthDiff <= 0 ? { cursor: "not-allowed" } : {}}
                >
                  Valider <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
        <Row className="forecast-row">
          {showForecastTable ? (
            <>
              <Button
                type="submit"
                htmlType="submit"
                className="change-forecast-data-btn"
                onClick={(e) => {
                  e.preventDefault();
                  showModal();
                }}
              >
                Mettre à jour la source de données
              </Button>
              <Modal
                title="Sélectionner la source de données "
                open={isModalOpen}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={handleCancel}
                bodyStyle={{ marginTop: "30px", marginLeft: "40px" }}
                width={450}
              >
                {btn_select}
              </Modal>
            </>
          ) : null}
          {showForecastTable ? (
            <>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div>
                  <Form.Item name="switchForecast" style={{ display: "inline-block" }}>
                    <Switch
                      checked={isForecastActivated}
                      onChange={() => {
                        setIsForecastActivated((previous) => !previous);
                      }}
                    />
                  </Form.Item>
                  <span style={{ marginLeft: "10px", verticalAlign: "-6px" }}>
                    Analyse par rapport au prévisonnel{" "}
                    <b>{isForecastActivated ? "activée" : "désactivée"}</b>
                  </span>
                </div>
                <p style={{ fontWeight: "bold", marginTop: "40px" }}>Dates de l'exercice :</p>

                <div className="date-input">
                  <Form.Item name="startDate">
                    <DatePicker
                      locale={{
                        ...fr,
                        lang: {
                          ...fr.lang,
                        },
                      }}
                      format={"MMMM YYYY"}
                      style={{ borderRadius: "30px" }}
                      allowClear={false}
                      onChange={onChangeStartDate}
                      picker="month"
                      status={monthDiff > 23 || monthDiff <= 0 ? "error" : null}
                      placeholder="Sélectionner une date"
                    />
                  </Form.Item>
                  <span>à</span>
                  <Form.Item name="endDate">
                    <DatePicker
                      locale={{
                        ...fr,
                        lang: {
                          ...fr.lang,
                        },
                      }}
                      format={"MMMM YYYY"}
                      style={{ borderRadius: "30px" }}
                      allowClear={false}
                      onChange={onChangeEndDate}
                      picker="month"
                      status={monthDiff > 23 || monthDiff <= 0 ? "error" : null}
                      placeholder="Sélectionner une date"
                    />
                  </Form.Item>
                  {monthDiff && monthDiff > 0 && monthDiff < 24 ? (
                    <div>
                      <span style={{ padding: "0 10px 0 10px" }}>-</span>Exercice de :{" "}
                      <b style={{ color: "blue" }}>{monthDiff} mois</b>
                    </div>
                  ) : null}
                </div>
                {monthDiff <= 1 ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    <InfoCircleOutlined /> La date de début d'exercice doit être antérieure à la
                    date de fin d'exercice
                  </div>
                ) : null}
                {monthDiff && monthDiff > 23 ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    <InfoCircleOutlined /> La période ne doit pas dépasser 23 mois
                  </div>
                ) : monthDiff === undefined ? (
                  <></>
                ) : null}
              </Col>

              <ClientForecastTable
                {...props}
                indicators={indicators}
                setIndicators={setIndicators}
                settings={settings}
                setSettings={setSettings}
                customIndicators={customIndicators}
                startDate={startDate}
                endDate={endDate}
              />
            </>
          ) : (
            <>
              <Row className="select">
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <p className="oups">Oups...</p>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <img src={HumanLostConnection} alt="human" className="img-human" />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} offset={4}>
                  <p>Il semble qu’il n’y ait pas de données prévisionnelles pour ce dossier.</p>
                  <p>Pour commencer :</p>
                  {btn_select}
                </Col>
              </Row>
            </>
          )}
        </Row>
        <Modal
          title="Charger le fichier RCA"
          className="edit-user-modal"
          open={showRCAUpload}
          onCancel={(e) => {
            e.preventDefault();
            setShowRCAUpload(false);
            setCurrent(0);
          }}
          footer={null}
          width={560}
        >
          <Steps size="small" current={current} items={items} className="rca-steps" />
          <div>{steps[current].content}</div>
          <div
            style={{
              marginTop: 24,
              textAlign: "right",
            }}
          >
            {current < steps.length - 1 && (
              <Button
                className="call-action-btn upload-rca-btn"
                onClick={() => setCurrent(current + 1)}
                disabled={monthDiff > 23 || monthDiff <= 1}
              >
                Suivant
              </Button>
            )}
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                className="other-action-btn "
                onClick={() => {
                  setCurrent(current - 1);
                  setUploadedRCAFile({});
                }}
              >
                Précédent
              </Button>
            )}
            {current === steps.length - 1 && (
              <>
                {Object.keys(uploadedRCAFile).length ? (
                  <Button
                    className="call-action-btn upload-rca-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFileUpload(uploadedRCAFile);
                      setCurrent(0);
                    }}
                  >
                    Valider
                  </Button>
                ) : null}
              </>
            )}
          </div>
        </Modal>
      </Form>
    </div>
  );
}

export default ClientForecast;
