import { LoadingOutlined } from "@ant-design/icons";
import { Badge, Skeleton, Spin, Tabs } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import * as API from "../../../api/API";
import ClientContext from "../../../context/ClientContext";
import { tabViews } from "../misc";
import ClientMissingBody from "./missing/missing";
import Outstandings from "./outstandings/Outstandings";
import ClientResponsiveTabBar from "./responsiveTabs";

const spinIcon = (
  <LoadingOutlined
    style={{
      fontSize: 10,
      marginLeft: "20px",
      marginBottom: "20px",
    }}
    spin
  />
);

function ClientBody(props) {
  const [missingDocument, setMissingDocument] = useState();
  const [missingDocumentOther, setMissingDocumentOther] = useState();
  const [initMissingDoc, setInitMissingDoc] = useState();
  const [initMissingDocOther, setInitMissingDocOther] = useState();
  const [lastMailSent, setLastMailSent] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [missingDocHeaderMessage, setMissingDocHeaderMessage] = useState();
  const [missingDocLostMessage, setMissingDocLostMessage] = useState();
  const [fiscalStartMonth, setFiscalStartMonth] = useState();
  const [missingDocDefaultView, setMissingDocDefaultView] = useState();

  const [activeTab, setActiveTab] = useState(tabViews[props.defaultView]);
  const [tabInfo, setTabInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fileSettings, setFileSettings] = useState();

  const [outstandingsClients, setOutstandingsClients] = useState();
  const [outstandingsClientsDueDays, setOutstandingsClientsDueDays] = useState();
  const [outstandingsProviders, setOutstandingsProviders] = useState();
  const [outstandingsProvidersDueDays, setOutstandingsProvidersDueDays] = useState();

  // TODO: Show a badge with the number of clients with outstandings
  const [nbrClients, setNbrClients] = useState(0);
  const [nbrProviders, setNbrProviders] = useState(0);
  const [outstandingsLoaded, setOutstandingsLoaded] = useState(false);

  const context = useContext(ClientContext);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const decodedToken = jwt_decode(props.tokenClient);

      let resClient = await API.getClient(props.idClient);
      resClient = await resClient.json();
      setTabInfo(resClient.clientPage);
      setMissingDocDefaultView(decodedToken.missingDocView || resClient.missingDocView);
      setOutstandingsClientsDueDays(resClient?.clientPage.outstandingsclients.dueDays);
      setOutstandingsProvidersDueDays(resClient?.clientPage.outstandingsproviders.dueDays);
      if (resClient?.email?.missingdoc?.lastEmailSent) {
        let emailDate = resClient.email.missingdoc.lastEmailSent;
        emailDate = dayjs(emailDate).format("DD/MM/YYYY");
        setLastMailSent(emailDate);
      }

      if (resClient.clientPage?.missingdoc?.display) {
        let res = await API.postToGetClientMissingDoc({ idClient: props.idClient });
        if (res.status === 200) {
          res = await res.json();
          setMissingDocument(res.accounts);
          setInitMissingDoc(res.accounts);
          if (res.filteredOther[0].operations.length > 0) {
            setMissingDocumentOther(res.filteredOther);
            setInitMissingDocOther(res.filteredOther);
          }

          setFiscalStartMonth(res.fiscalStartMonth);
        }
      }

      const resAccountingFirm = await API.getAccountingFirm(context.idAccountingFirm);
      if (resAccountingFirm.status === 200) {
        const resAccountingFirmJson = await resAccountingFirm.json();
        setMissingDocHeaderMessage(resAccountingFirmJson.clientPageCustomText);
        setMissingDocLostMessage(resAccountingFirmJson.lostDocCustomText);
      }

      if (
        resClient.clientPage?.outstandingsclients?.display ||
        resClient.clientPage?.outstandingsproviders?.display
      ) {
        let outstandings = await API.getOutstandingsClient(props.idClient);
        if (outstandings.status === 200) {
          outstandings = await outstandings.json();

          outstandings.clients = outstandings.clients
            ?.map((cat) => ({
              ...cat,
              operations: cat.operations
                .filter((op) => op.showToClient)
                .map((op) => ({ ...op, categoryId: cat._id })),
            }))
            ?.filter((cat) => cat.operations.length > 0);
          outstandings.providers = outstandings.providers
            ?.map((cat) => ({
              ...cat,
              operations: cat.operations
                .filter((op) => op.showToClient)
                .map((op) => ({ ...op, categoryId: cat._id })),
            }))
            ?.filter((cat) => cat.operations.length > 0);
          setOutstandingsClients(outstandings.clients);
          setOutstandingsProviders(outstandings.providers);
        } else if (outstandings.status === 401) props.setExpiredToken(true);
        setOutstandingsLoaded(true);
      }
      setIsLoading(false);
    })();
  }, [props.idClient]);

  useEffect(() => {
    (async () => {
      let res = await API.getAccountingFirmFileSettings(context.idAccountingFirm);
      res = await res.json();
      setFileSettings(res);
    })();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const items = [
    {
      key: "1",
      forceRender: true,
      label: tabInfo?.missingdoc?.display ? (
        <Badge
          count={
            isLoading ? (
              <Spin className="badge-spin" indicator={spinIcon} />
            ) : (
              (missingDocumentOther?.[0]?.operations?.length || 0) +
              (missingDocument?.reduce((total, objet) => {
                return total + objet.operations.length;
              }, 0) || 0)
            )
          }
          className="badge-count"
        >
          <span>Pièces manquantes</span>
        </Badge>
      ) : (
        <span>Pièces manquantes</span>
      ),
      disabled: !tabInfo?.missingdoc?.display,
      children: (
        <ClientMissingBody
          missingDocument={missingDocument}
          setMissingDocument={setMissingDocument}
          initMissingDoc={initMissingDoc}
          setInitMissingDoc={setInitMissingDoc}
          missingDocumentOther={missingDocumentOther}
          setMissingDocumentOther={setMissingDocumentOther}
          initMissingDocOther={initMissingDocOther}
          setInitMissingDocOther={setInitMissingDocOther}
          missingDocHeaderMessage={missingDocHeaderMessage}
          missingDocLostMessage={missingDocLostMessage}
          fiscalStartMonth={fiscalStartMonth}
          lastMailSent={lastMailSent}
          screenWidth={screenWidth}
          tokenClient={props.tokenClient}
          missingDocDefaultView={missingDocDefaultView}
          fileSettings={fileSettings}
        />
      ),
    },
    {
      key: "2",
      forceRender: true,
      label: tabInfo?.outstandingsclients?.display ? (
        <Badge
          count={
            !outstandingsLoaded ? <Spin className="badge-spin" indicator={spinIcon} /> : nbrClients
          }
          className="badge-count"
        >
          <span>Encours clients</span>
        </Badge>
      ) : (
        <span>Encours clients</span>
      ),
      disabled: !tabInfo?.outstandingsclients?.display,
      children:
        outstandingsClients === undefined ? (
          <Skeleton />
        ) : (
          <Outstandings
            {...props}
            data={outstandingsClients}
            setData={setOutstandingsClients}
            dueDays={outstandingsClientsDueDays}
            type="clients"
            setBadgeCount={setNbrClients}
            missingDoc={missingDocument}
            outstandingClientDueDay={tabInfo?.outstandingsclients?.dueDays || 0}
            endDate={tabInfo?.outstandingsclients?.endDate}
            screenWidth={screenWidth}
            setActiveTab={setActiveTab}
          />
        ),
    },
    {
      key: "3",
      forceRender: true,
      label: tabInfo?.outstandingsproviders?.display ? (
        <Badge
          count={
            !outstandingsLoaded ? (
              <Spin className="badge-spin" indicator={spinIcon} />
            ) : (
              nbrProviders
            )
          }
          className="badge-count"
        >
          <span>Encours fournisseurs</span>
        </Badge>
      ) : (
        <span>Encours fournisseurs</span>
      ),
      disabled: !tabInfo?.outstandingsproviders?.display,
      children:
        outstandingsProviders === undefined ? (
          <Skeleton />
        ) : (
          <Outstandings
            {...props}
            data={outstandingsProviders}
            setData={setOutstandingsProviders}
            dueDays={outstandingsProvidersDueDays}
            type="providers"
            outstandingClientDueDay={tabInfo?.outstandingsproviders?.dueDays || 0}
            endDate={tabInfo?.outstandingsproviders?.endDate}
            missingDoc={missingDocument}
            screenWidth={screenWidth}
            setBadgeCount={setNbrProviders}
          />
        ),
    },
  ];

  return isLoading ? (
    <section>
      <Skeleton active />
      <br />
      <Skeleton active />
    </section>
  ) : (
    <>
      {screenWidth > 900 ? (
        <section>
          <Tabs activeKey={activeTab} items={items} onChange={setActiveTab} />
        </section>
      ) : (
        <ClientResponsiveTabBar tabs={items} />
      )}
    </>
  );
}

export default ClientBody;
