import { Table } from "antd";
import { useEffect, useRef, useState } from "react";

import * as API from "../../../../api/API";
import { addSearchAndSorterToColumns } from "../../../../utils/table";

function LastUpdateTable(props) {
  const [clientsData, setClientsData] = useState();
  const [loadingData, setLoadingData] = useState(true);

  const [searchTexts, setSearchTexts] = useState({});
  const [searchedColumn, setSearchedColumn] = useState([]);
  const searchInput = useRef(null);

  let searchVariables = {
    searchTexts,
    setSearchTexts,
    searchedColumn,
    setSearchedColumn,
    searchInput: searchInput,
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "updateDate",
      key: "updateDate",
      align: "center",
      width: "40px",
    },
    {
      title: "Heure",
      dataIndex: "updateHours",
      key: "updateHours",
      align: "center",
      width: "40px",
    },
  ];

  addSearchAndSorterToColumns(columns, searchVariables, clientsData);

  useEffect(() => {
    (async () => {
      setLoadingData(true);

      let res = await API.getModificationDatesByClient(
        props.currentAccFirmId,
        props.displayedClients[0].remoteId
      );
      res = await res.json();

      setClientsData(res.map(d => ({ ...d, key: d.updateDate + d.updateHours })));
      setLoadingData(false);
    })();
  }, [props.displayedClients]);

  return (
    <Table
      columns={columns}
      dataSource={clientsData}
      loading={props.loadingDisplayedClients || loadingData}
      size="small"
      bordered
      pagination={true}
      rowKey={(record) => record.key}
      scroll={{
        x: "max-content",
      }}
    />
  );
}

export default LastUpdateTable;
