import { message } from "antd";
import { AttachmentDocHelper } from "helpers/analysis/attachmentDocHelper";
import { ReceivedDocHelper } from "helpers/analysis/received/receivedDocHelper";

import * as API from "../../api/API";
import { options } from "../../components/client/misc";
import logAction from "../../utils/logActions";

const getTypeId = (amount) => {
  if (!amount) return "Autres";
  if (amount > 0) return "Encaissements à justifier";
  return "Décaissements à justifier";
};

const sanitizeFileName = (originalFileName, operation, iphone) => {
  let nameWithoutExt = originalFileName.replace(/\.[^/.]+$/, "");
  let random = "";
  nameWithoutExt = nameWithoutExt.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
  nameWithoutExt = nameWithoutExt.length > 50 ? nameWithoutExt.substring(0, 50) : nameWithoutExt;

  if (iphone) random = Math.floor(Math.random() * 9000 + 1000);

  if (!operation) {
    return `${nameWithoutExt}${random}.${originalFileName.split(".").pop()}`;
  }

  operation = operation.replace(/<[^>]+>/gi, "");
  operation = operation.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
  operation = operation.length > 25 ? operation.substring(0, 25) : operation;

  return `${nameWithoutExt}${random}_${operation}.${originalFileName.split(".").pop()}`;
};

const getGed = (decodedToken) => {
  const dataStorageProviders = [];
  if (decodedToken.iDepot) dataStorageProviders.push("iDepot");
  if (decodedToken.conciliator) dataStorageProviders.push("Conciliator");
  if (decodedToken.iDocus) dataStorageProviders.push("iDocus");
  if (decodedToken.mcf) dataStorageProviders.push("MCF");

  return dataStorageProviders;
};

const updateStatesHelper = (fileList, setData, operation, comment, isLost, isLostNoAccount) => {
  setData((previous) => {
    return previous.map((el) => {
      return {
        ...el,
        operations: el.operations.map((o) => {
          if (o._id === operation._id) {
            return { ...o, files: [...fileList], comment, isLost, isLostNoAccount };
          }
          return o;
        }),
      };
    });
  });
};

const handleOnRemoveHelper = async (fileList, operation, clientId, decodedToken) => {
  const res = [];
  for (const file of fileList) {
    const response = await API.deleteMissingDocFile(
      clientId,
      operation,
      file.uid,
      getTypeId(operation.amount),
      getGed(decodedToken)
    );
    res.push(response);
  }

  return res;
};

const fileSizeIsOkHelper = (file, maxFileSizeInMB) => {
  if (maxFileSizeInMB === null) {
    return true;
  }
  if (maxFileSizeInMB * 1048576 < file.size) {
    message.error(`La taille du fichier dépasse la limite autorisée de ${maxFileSizeInMB} Mo.`);
    return false;
  }
  return true;
};

const fileFormatIsOkHelper = (file, authorizedFormats) => {
  if (authorizedFormats?.length === 0) return true;
  if (!authorizedFormats.includes(file.type)) {
    message.error(
      `"${file.type}" n'est pas un format de fichier valide. Formats autorisés : ${authorizedFormats
        .map((f) => options.find((o) => f === o.value)?.label)
        .join(", ")}`
    );
    return false;
  }
  return true;
};

/**
 * Sanitizes a file name by removing special characters and spaces.
 */
const fileNameIsOkHelper = (file, operation, fileList) => {
  const uploadedFileNames = operation.files.map((f) => f.fileName);
  const sanitizedName =
    file.name === "image.jpg"
      ? sanitizeFileName(file.name, operation.text, true)
      : sanitizeFileName(file.name, operation.text, false);

  if (uploadedFileNames.includes(file.name) || uploadedFileNames.includes(sanitizedName)) {
    message.error(`"${file.name}" est déjà partagé.`);
    return false;
  }
  const fileNamesLoaded = fileList.map((f) => f.name);
  if (fileNamesLoaded.includes(file.name)) {
    message.error(`"${file.name}" est déjà en cours de chargement.`);
    return false;
  }

  return true;
};

const handleUploadHelper = async (file, operation, missingDoc, decodedToken, isOther) => {
  const fileName =
    file.name === "image.jpg"
      ? sanitizeFileName(file.name, !isOther ? operation.text : undefined, true)
      : sanitizeFileName(file.name, !isOther ? operation.text : undefined, false);

  let formData = new FormData();

  formData.append("files", file, fileName);
  formData.set(
    "input",
    JSON.stringify({
      typeId: getTypeId(operation.amount),
      operation: operation,
    })
  );
  formData.set("idClient", decodedToken.clientId);
  formData.set("ged", getGed(decodedToken));

  let upload = await API.postUploadMissingDocFile(formData);
  if (upload.status !== 200) {
    return {
      status: 400,
      message: `Erreur lors de l'import pour le fichier "${fileName}", le fichier est peut-être corrompu`,
    };
  }

  logAction(110, 1, decodedToken.clientId);
  API.postClientTracker(decodedToken.clientId, {
    action: "UPLOAD",
    operationId: operation._id,
    dateOp: operation.date,
    type: getTypeId(operation.amount),
    category: missingDoc?.title !== undefined ? missingDoc.title.replace("/", "%2F") : "Autres",
    label: operation.text,
    reference: operation.piece_ref,
    amount: operation.amount,
    nature: operation.nature,
    fileName: fileName,
  });

  API.notify({ clientId: decodedToken.clientId, action: "missingDoc" });

  return { status: 200, message: "Votre fichier a bien été envoyé", data: upload.data };
};

const updateLostDocumentHelper = async (
  idClient,
  operation,
  isLost,
  isLostNoAccount,
  setData,
  category
) => {
  const res = await API.UpdateOperation({
    clientId: idClient,
    operation,
    isLost: isLost,
    isLostNoAccount: isLostNoAccount,
  });
  if (res.status === 200) {
    setData((previous) => {
      return previous.map((el) => {
        return {
          ...el,
          operations: el.operations.map((o) => {
            if (o._id === operation._id) {
              return { ...o, isLost: isLost, isLostNoAccount: isLostNoAccount };
            }
            return o;
          }),
        };
      });
    });
    API.postClientTracker(idClient, {
      action: "LOST",
      operationId: operation._id,
      dateOp: operation.date,
      label: operation.text,
      reference: operation.piece_ref,
      amount: operation.amount,
      nature: operation.nature,
      isLost: isLost || isLostNoAccount,
      category: category?.title !== undefined ? category.title.replace("/", "%2F") : "Autres",
    });
    if (isLost || isLostNoAccount) API.notify({ clientId: idClient, action: "missingDoc" });
  }
};

const downloadHelper = async (clientId, idOp, file) => {
  await ReceivedDocHelper.downloadFiles({ _id: clientId }, [file], idOp, false);
};

const downloadAttachmentHelper = async (file) => {
  await AttachmentDocHelper.downloadFiles([file]);
};

export {
  downloadAttachmentHelper,
  downloadHelper,
  fileFormatIsOkHelper,
  fileNameIsOkHelper,
  fileSizeIsOkHelper,
  handleOnRemoveHelper,
  handleUploadHelper,
  updateLostDocumentHelper,
  updateStatesHelper,
};
