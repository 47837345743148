import * as API from "../../api/API";
import { views } from "../../components/client/misc";

// ============================== General ==============================

const getSumHelper = (missingDocument) => {
  const total = missingDocument
    .map((element) => {
      return element.operations.reduce((accumulator, obj) => accumulator + Math.abs(obj.amount), 0);
    })
    .reduce((accumulator, obj) => accumulator + obj, 0);
  return total;
};

// ============================== Desktop Specific ==============================
const isExpandedByDefaultHelper = (missingDocument) => {
  if (!missingDocument) return false;
  return (
    missingDocument.map(({ operations }) => operations).flat(1).length <
    process.env.REACT_APP_EXPAND_LIMIT
  );
};

// ============================== Operations ==============================

const toggleEditOperationHelper = (operation, setOperationsEditModeList) => {
  setOperationsEditModeList((previous) => ({
    ...previous,
    [operation._id]: !previous[operation._id],
  }));
};

const clientUpdateOperationHelper = async (
  idClient,
  operation,
  title,
  comment,
  isLost,
  isLostNoAccount
) => {
  const getTypeId = () => {
    if (!operation.amount) return "Autres";
    if (operation.amount > 0) return "Encaissements à justifier";
    return "Décaissements à justifier";
  };

  const res = await API.UpdateOperation({
    clientId: idClient,
    title: title !== undefined ? title.replace("/", "%2F") : undefined,
    typeId: getTypeId(),
    operation,
    comment: comment,
    isLost: isLost,
    isLostNoAccount: isLostNoAccount,
    nature: operation.nature,
  });
  if (res.status === 200) {
    API.postClientTracker(idClient, {
      action: "LOST",
      operationId: operation._id,
      dateOp: operation.date,
      type: getTypeId(),
      category: title !== undefined ? title.replace("/", "%2F") : "Autres",
      label: operation.text,
      reference: operation.piece_ref,
      amount: operation.amount,
      nature: operation.nature,
      isLost: isLost || isLostNoAccount,
    });
    if (isLost || isLostNoAccount) API.notify({ clientId: idClient, action: "missingDoc" });
  }
};

// ============================== Views ==============================

const filterViewOthersHelper = (missingDocOthers, selectionOfCloseDate, fiscalMonth) => {
  return {
    ...views[0],
    data: filterMissingDoc(missingDocOthers, selectionOfCloseDate, fiscalMonth),
  };
};

const filterView1Helper = (missingDoc, selectionOfCloseDate, fiscalMonth) => {
  // 0 = Encaissements, 1 = Décaissements
  const catByType = { 0: [], 1: [] };

  missingDoc.forEach((account) => {
    account.operations.forEach((operation) => {
      const type = operation.amount > 0 ? 0 : 1;

      const catByTypeHasAccount = catByType[type].some(
        (e) => e.accountNumberAux === account.accountNumberAux
      );
      if (!catByTypeHasAccount) {
        catByType[type].push({ ...account, operations: [] });
      }

      let accountIndex = catByType[type].findIndex(
        (e) => e.accountNumberAux === account.accountNumberAux
      );

      const accountIsOther = ["40", "41"].includes(account.accountNumber) === false;
      if (accountIsOther && catByType[type][accountIndex].title !== account.title) {
        catByType[type].push({ ...account, operations: [] });
        accountIndex = catByType[type].findIndex((e) => e.title === account.title);
      }

      catByType[type][accountIndex].operations.push(operation);
    });
  });

  return views[1].map((v) => {
    return {
      ...v,
      data:
        v.label === "Décaissements à justifier"
          ? filterMissingDoc(catByType[1], selectionOfCloseDate, fiscalMonth)
          : filterMissingDoc(catByType[0], selectionOfCloseDate, fiscalMonth),
    };
  });
};

const filterView2Helper = (missingDoc, selectionOfCloseDate, fiscalMonth) => {
  const missingDocAch = missingDoc.filter((account) => account.accountNumber === "40");
  const missingDocVen = missingDoc.filter((account) => account.accountNumber === "41");
  const missingDocAux = missingDoc.filter(
    (account) => account.accountNumber !== "40" && account.accountNumber !== "41"
  );
  return views[2].map((v) => {
    return {
      ...v,
      data:
        v.label === "Achats"
          ? filterMissingDoc(missingDocAch, selectionOfCloseDate, fiscalMonth)
          : v.label === "Ventes"
          ? filterMissingDoc(missingDocVen, selectionOfCloseDate, fiscalMonth)
          : filterMissingDoc(missingDocAux, selectionOfCloseDate, fiscalMonth),
    };
  });
};

const filterView3Helper = (missingDoc, selectionOfCloseDate, fiscalMonth) => {
  const listOfJournals = [];

  missingDoc.forEach((account) => {
    if (account.operations.length > 0) {
      const journals = account.operations.map(
        (operation) => `${operation.journal} ${operation.journalLabel}`
      );
      listOfJournals.push(...journals);
    }
  });

  const uniqueJournals = [...new Set(listOfJournals)];

  const missingDocJournaux = uniqueJournals.map((journal) => {
    const splitJournal = journal.split(" ");
    const id = splitJournal[0];
    const label = splitJournal.slice(1).join(" ");

    const data = filterMissingDoc(
      missingDoc.filter((account) =>
        account.operations.some(
          (operation) => operation.journal === id && operation.journalLabel === label
        )
      ),
      selectionOfCloseDate,
      fiscalMonth
    );

    data.forEach((element) => {
      element.operations = element.operations.filter(
        (operation) => operation.journal === id && operation.journalLabel === label
      );
    });

    return {
      id,
      label: journal,
      data,
    };
  });

  return missingDocJournaux;
};

const filterViewInnerJournalsHelper = (missingDoc) => {
  const missingDocAch = missingDoc.filter((account) => account.accountNumber === "40");
  const missingDocVen = missingDoc.filter((account) => account.accountNumber === "41");
  const missingDocAux = missingDoc.filter(
    (account) => account.accountNumber !== "40" && account.accountNumber !== "41"
  );
  return views[2].map((v) => {
    return {
      ...v,
      data:
        v.label === "Achats" ? missingDocAch : v.label === "Ventes" ? missingDocVen : missingDocAux,
    };
  });
};

// ============================== Statistics and filter ==============================

const filterMissingDoc = (missingDoc, selectionOfCloseDate, fiscalMonth) => {
  const dataSourceMissingDoc = [];
  missingDoc.forEach((item) => {
    if (item.operations.length > 0) {
      const filteredOperations = item.operations.filter((operation) =>
        filterOperationByDate(operation, selectionOfCloseDate, fiscalMonth)
      );
      dataSourceMissingDoc.push({
        ...item,
        operations: filteredOperations,
      });
    }
  });
  return dataSourceMissingDoc;
};

const determineFiscalYearEndHelper = (fiscalStartMonth) => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const fiscalEndMonth = fiscalStartMonth - 1 === 0 ? 12 : fiscalStartMonth - 1;

  return currentMonth >= fiscalEndMonth ? currentYear + 1 : currentYear;
};

const filterOperationByDate = (operation, selectionOfCloseDate, fiscalStartMonth) => {
  const dateIsIn = {};
  const operationDate = new Date(operation.date);
  const fiscalYearEnd = determineFiscalYearEndHelper(fiscalStartMonth);

  const getFiscalYearDates = (endYear) => {
    const fiscalEndMonth = fiscalStartMonth - 1 === 0 ? 12 : fiscalStartMonth - 1;
    const startDate = new Date(endYear - 1, fiscalEndMonth, 1);
    const endDate = new Date(endYear, fiscalEndMonth, 0);
    return { startDate, endDate };
  };

  // Calculer les trois années fiscales
  for (let i = -2; i <= 0; i++) {
    const fiscalYear = fiscalYearEnd + i;
    const { startDate, endDate } = getFiscalYearDates(fiscalYear);

    let dateIsInThatYear;
    if (i === -2) {
      // Pour la première itération (année la plus ancienne), vérifier seulement si la date est avant la fin
      dateIsInThatYear = operationDate <= endDate;
    } else {
      // Pour les autres années, vérifier si la date est dans l'intervalle
      dateIsInThatYear = operationDate >= startDate && operationDate <= endDate;
    }

    dateIsIn[fiscalYear] = dateIsInThatYear;
  }

  // Vérifier si l'opération doit être filtrée
  if (
    (!selectionOfCloseDate[0] && dateIsIn[fiscalYearEnd - 2]) ||
    (!selectionOfCloseDate[1] && dateIsIn[fiscalYearEnd - 1]) ||
    (!selectionOfCloseDate[2] && dateIsIn[fiscalYearEnd])
  ) {
    return false;
  }
  return true;
};

const getNumberOfMissingDocumentsHelper = (missingDocument, missingDocumentOther) => {
  let total = 0;

  missingDocument.forEach((element) => {
    element.data.forEach((obj) => (total += obj.operations.length));
  });

  if (missingDocumentOther)
    total += missingDocumentOther.reduce(
      (accumulator, obj) => accumulator + obj.operations.filter((op) => op.showToClient).length,
      0
    );

  return total;
};

export {
  clientUpdateOperationHelper,
  determineFiscalYearEndHelper,
  filterView1Helper,
  filterView2Helper,
  filterView3Helper,
  filterViewInnerJournalsHelper,
  filterViewOthersHelper,
  getNumberOfMissingDocumentsHelper,
  getSumHelper,
  isExpandedByDefaultHelper,
  toggleEditOperationHelper,
};
