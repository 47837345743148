import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Row, Select, Switch, Typography } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { selectClient } from "../../../slices/clientSlice";
import DataTuning from "./DataTuning";
import TableOrder from "./TableOrder.js";
import Variation from "./Variation.js";

const { Text } = Typography;
const { Option } = Select;

const months = [
  "janv.",
  "févr.",
  "mars",
  "avr.",
  "mai",
  "juin",
  "juil.",
  "août",
  "sept.",
  "oct.",
  "nov.",
  "déc.",
];

const numberWithThousandSeparator = (x) => {
  if (x !== "") {
    return (
      Math.round(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"
    );
  }
};

const monthNameToNum = (monthname) => {
  let month = months.indexOf(monthname);
  return month + 1;
};

const monthNumToName = (num) => months[num - 1];

const indicName = {
  ca: "Chiffre d'affaires",
  marge: "Marge",
  masse_sal: "Masse salariale",
  treso: "Trésorerie",
  charges_ext: "Charges externes",
  result: "Résultat",
};

const AnalyseTable = (props) => {
  const client = useSelector(selectClient);
  dayjs.locale("fr");

  const start_date =
    monthNumToName(props.analysisData.general.analysed_month_number) +
    " " +
    props.analysisData.general.analysed_fiscal_year;

  const [date_value, setValue] = useState(start_date);
  const [display_marge_value, setDisplayMarge] = useState(true);
  const [display_masse_sal_value, setDisplayMasseSal] = useState(true);
  const [dateArray, setDateArray] = useState([]);
  const [graphs, setGraphs] = useState({});
  const [columnsYOY, setColumnsYOY] = useState([]);
  const [columnsCumul, setColumnsCumul] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const cumultable = React.createRef();
  const yoytable = React.createRef();

  useEffect(() => {
    for (let i = 0; i < 13; i++) {
      setDateArray((dateArray) => [...dateArray, dayjs().subtract(i, "months").format("MMM YYYY")]);
    }
  }, []);

  useEffect(() => {
    if (client) {
      setStartDate(
        dayjs()
          .set("month", client?.forecast?.settings?.start_month)
          .set("year", client?.forecast?.settings?.start_year)
          .format("MMM YY")
      );
      setEndDate(
        dayjs()
          .set("month", client?.forecast?.settings?.end_month)
          .set("year", client?.forecast?.settings?.fiscal_year)
          .format("MMM YY")
      );
    }
  }, [client]);

  useEffect(() => {
    setValue(start_date);
  }, [start_date]);

  useEffect(() => {
    let localGraphs = { ...props.analysisData.graphs };
    localGraphs.table_yoy = localGraphs.table_yoy.map((elt) => ({
      ...elt,
      marge_percent: elt.ca !== 0 ? ((elt.marge / elt.ca) * 100).toFixed(2) : "NA",
      masse_sal_percent: elt.ca !== 0 ? ((elt.masse_sal / elt.ca) * 100).toFixed(2) : "NA",
    }));

    localGraphs.table_cumul = localGraphs.table_cumul.map((elt) => ({
      ...elt,
      marge_percent: elt.ca !== 0 ? ((elt.marge / elt.ca) * 100).toFixed(2) : "NA",
      masse_sal_percent: elt.ca !== 0 ? ((elt.masse_sal / elt.ca) * 100).toFixed(2) : "NA",
    }));
    setGraphs(localGraphs);

    const columns_yoy = [];
    const columns_cumul = [];
    props.indicsTableOrder
      .filter(
        (elt) =>
          props.analysisTemplate.templateAnalysis.find((obj) => obj.id === elt).visible ||
          props.analysisTemplate.templateAnalysis.find((obj) => obj.id === elt).visible ===
            undefined
      )
      .filter((elt) => elt.id !== "treso_dispo")
      .forEach((indic) => {
        columns_yoy.push({
          selector: indic,
          name: (
            <>
              {indic === "marge" ? (
                <Switch
                  checkedChildren="€"
                  unCheckedChildren="%"
                  checked={display_marge_value}
                  onChange={(event) => {
                    setDisplayMarge(event);
                  }}
                  size="small"
                  className="switch-marge"
                />
              ) : indic === "masse_sal" ? (
                <Switch
                  checkedChildren="€"
                  unCheckedChildren="%"
                  checked={display_masse_sal_value}
                  onChange={(event) => {
                    setDisplayMasseSal(event);
                  }}
                  size="small"
                  className="switch-massal"
                />
              ) : null}
              <span>
                {indic === "treso"
                  ? ""
                  : props.analysisTemplate.templateAnalysis.find((obj) => obj.id === indic)?.name ||
                    indicName[indic]}
              </span>
            </>
          ),
          format: (datum) => {
            switch (indic) {
              case "marge":
                return display_marge_value
                  ? numberWithThousandSeparator(datum.marge)
                  : datum.marge_percent + "%";
              case "masse_sal":
                return display_masse_sal_value
                  ? numberWithThousandSeparator(datum.masse_sal)
                  : datum.masse_sal_percent + "%";

              default:
                return numberWithThousandSeparator(datum[indic]);
            }
          },
          cell: (row, index, column) =>
            indic === "treso" ? null : Object.keys(localGraphs).length > 0 ? (
              <CellVar
                row={row}
                index={index}
                column={column}
                table_type="table_yoy"
                dataGraphs={localGraphs}
              />
            ) : null,
          right: true,
          width: "25%",
        });

        columns_cumul.push({
          selector: indic,
          name:
            props.analysisTemplate.templateAnalysis.find((obj) => obj.id === indic)?.name ||
            indicName[indic],
          format: (datum) => {
            switch (indic) {
              case "marge":
                return display_marge_value
                  ? numberWithThousandSeparator(datum.marge)
                  : datum.marge_percent + "%";
              case "masse_sal":
                return display_masse_sal_value
                  ? numberWithThousandSeparator(datum.masse_sal)
                  : datum.masse_sal_percent + "%";

              default:
                return numberWithThousandSeparator(datum[indic]);
            }
          },
          cell: (row, index, column) =>
            Object.keys(localGraphs).length > 0 ? (
              <CellVar
                row={row}
                index={index}
                column={column}
                table_type="table_cumul"
                dataGraphs={localGraphs}
              />
            ) : null,
          right: true,
          width: "25%",
        });
      });
    setColumnsYOY(columns_yoy);
    setColumnsCumul(columns_cumul);
  }, [
    props.analysisData.graphs,
    props.indicsTableOrder,
    display_marge_value,
    display_masse_sal_value,
  ]);

  const CellVar = ({ row, index, column, table_type, dataGraphs }) => {
    if (index === 0) {
      if (column.selector === "marge") {
        return (
          <div className="cell-div">
            {(row.ca === 0 && display_marge_value === false) || client.pastYearComp ? null : (
              <Variation
                {...props}
                data={row}
                column={column}
                type={table_type}
                percent={display_marge_value}
                graphs={dataGraphs}
              />
            )}
            <Text className="first-row">
              {column.selector === "marge" && display_marge_value
                ? numberWithThousandSeparator(row[column.selector])
                : row[column.selector + "_percent"] === "NA"
                ? row[column.selector + "_percent"]
                : row[column.selector + "_percent"] + "%"}
            </Text>
          </div>
        );
      } else {
        if (column.selector === "masse_sal") {
          return (
            <div className="cell-div">
              {(row.ca === 0 && display_masse_sal_value === false) || client.pastYearComp ? null : (
                <Variation
                  {...props}
                  data={row}
                  column={column}
                  type={table_type}
                  percent={display_masse_sal_value}
                  graphs={dataGraphs}
                />
              )}
              <Text className="first-row">
                {column.selector === "masse_sal" && display_masse_sal_value
                  ? numberWithThousandSeparator(row[column.selector])
                  : row[column.selector + "_percent"] === "NA"
                  ? row[column.selector + "_percent"]
                  : row[column.selector + "_percent"] + "%"}
              </Text>
            </div>
          );
        } else {
          return (
            <div className="cell-div">
              {client.pastYearComp ? null : (
                <Variation
                  {...props}
                  data={row}
                  column={column}
                  type={table_type}
                  graphs={dataGraphs}
                />
              )}
              <Text className="first-row">{numberWithThousandSeparator(row[column.selector])}</Text>
            </div>
          );
        }
      }
    } else {
      if (column.selector === "marge" || column.selector === "masse_sal") {
        return (
          <Text
            className="second-row"
            style={client.pastYearComp ? { visibility: "hidden" } : null}
          >
            {(column.selector === "marge" && display_marge_value) ||
            (column.selector === "masse_sal" && display_masse_sal_value)
              ? numberWithThousandSeparator(row[column.selector])
              : row[column.selector + "_percent"] === "NA"
              ? row[column.selector + "_percent"]
              : row[column.selector + "_percent"] + "%"}
          </Text>
        );
      } else {
        return (
          <Text
            style={client.pastYearComp ? { visibility: "hidden" } : null}
            className="second-row"
          >
            {numberWithThousandSeparator(row[column.selector])}
          </Text>
        );
      }
    }
  };

  const updateAnalysis = (value) => {
    setValue(value);
    props.selectAnalyseDate({
      month: monthNameToNum(value.split(" ")[0]),
      fiscal_year: value.split(" ")[1],
    });
  };

  useEffect(() => {
    props.selectTable({
      para: "table",
      date: date_value,
      display_marge_value: display_marge_value,
      display_masse_sal_value: display_masse_sal_value,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.update]);

  return (
    <>
      <div className="table" style={client.pastYearComp ? { paddingBottom: 0 } : null}>
        <div style={{ display: "inline-block" }}>
          <TableOrder {...props} />
        </div>
        <div style={{ display: "inline-block", marginLeft: "20px", verticalAlign: "text-bottom" }}>
          <DataTuning {...props} dataTuning={client.dataTuning} />
        </div>

        <div style={{ position: "absolute", width: "100%", top: "20px" }}>
          {client.forecast?.settings?.active?.global ? (
            <span
              className="analysis-title"
              style={{ fontSize: "20px", fontWeight: "bold", marginLeft: "29%" }}
            >
              <span>Analyse par rapport au</span>
              <span style={{ color: "var(--blue)" }}>
                {" "}
                prévisionnel ({`${startDate} - ${endDate}`})
              </span>
            </span>
          ) : null}{" "}
        </div>

        <Row className="row-yoy" style={client.pastYearComp ? { marginBottom: 0 } : null}>
          <Col xs={24} sm={24} md={4} lg={4}>
            <div className="table-dates">
              <Select
                className="select-date"
                value={date_value}
                onChange={(value) => updateAnalysis(value)}
                popupClassName="ant-select-dropdown-analysis"
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
              >
                {dateArray.map((date, i) => (
                  <Option key={i} value={date}>
                    {date}
                  </Option>
                ))}
              </Select>
              <span
                className="responsive-vs"
                style={client.pastYearComp ? { visibility: "hidden" } : null}
              >
                {" "}
                vs{" "}
                {client.forecast?.settings?.active?.global
                  ? "Prévisionnel"
                  : monthNumToName(props.analysisData.general.analysed_month_number) +
                    " " +
                    (props.analysisData.general.analysed_fiscal_year - 1).toString()}
              </span>
              <div
                className="not-colored"
                style={client.pastYearComp ? { visibility: "hidden" } : null}
              >
                <Text>
                  {client.forecast?.settings?.active?.global
                    ? "Prévisionnel"
                    : monthNumToName(props.analysisData.general.analysed_month_number) +
                      " " +
                      (props.analysisData.general.analysed_fiscal_year - 1).toString()}
                  {}
                </Text>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={19} lg={19}>
            <>
              <div id="yoy" ref={yoytable}>
                <DataTable
                  className="yoy-table"
                  noHeader={true}
                  columns={columnsYOY}
                  data={graphs.table_yoy}
                />
              </div>
            </>
          </Col>
        </Row>
        <Row className="row-cumul">
          <Col xs={24} sm={24} md={4} lg={4}>
            <div className="table-dates">
              <div className="colored" style={{ paddingBottom: "3.5px" }}>
                <Text>
                  Cumul {monthNumToName(props.analysisData.general.analysed_month_number)}{" "}
                  {date_value.match(/\d+/g).map(Number)}
                </Text>
              </div>
              <div
                className="not-colored"
                style={
                  client.pastYearComp
                    ? { visibility: "hidden", paddingBottom: "3.5px" }
                    : { paddingBottom: "3.5px" }
                }
              >
                <Text>
                  Cumul{" "}
                  {client.forecast?.settings?.active?.global
                    ? "prévisionnel"
                    : monthNumToName(props.analysisData.general.analysed_month_number) +
                      " " +
                      (date_value.match(/\d+/g).map(Number) - 1)}
                </Text>
              </div>

              <span className="responsive-vs">
                Cumul {date_value.match(/\d+/g).map(Number)}{" "}
                {client.pastYearComp
                  ? null
                  : "vs Cumul " +
                    (client.forecast?.settings?.active?.global
                      ? "prévisionnel"
                      : date_value.match(/\d+/g).map(Number) - 1)}
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={19} lg={19}>
            <div id="cumul" ref={cumultable}>
              <DataTable
                className="cumul-table"
                columns={columnsCumul}
                noHeader={true}
                data={graphs.table_cumul}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Popover
        placement="right"
        content="Cette analyse est valable sous réserve que la comptabilité soit à jour et que le dossier soit en comptabilité d’engagement."
        className="info-popover"
        overlayClassName="info-popover-overlay"
      >
        <span>
          <InfoCircleOutlined />
        </span>
      </Popover>
      <span className="responsive-popover">
        Cette analyse est valable sous réserve que la comptabilité soit à jour et que le dossier
        soit en comptabilité d’engagement.
      </span>
    </>
  );
};

export default AnalyseTable;
