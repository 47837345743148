import { CloseCircleOutlined, MailOutlined, UpCircleOutlined } from "@ant-design/icons";
import { Affix, Button, Collapse, Drawer, Modal, Result, Skeleton, Tag } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../../api/API";
import { ReactComponent as ReminderTrashBin } from "../../../assets/images/trash_bin.svg";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import { getMailDate } from "../../../utils/getDate";

import "../MissingDocuments.css";
import Header from "./Header";
import Other from "./Other";
import Index from "./operations/Index";

const defaultTemplate = [
  {
    _id: "MISSING_DOC",
    name: "Modèle Sesha",
    type: "MISSING_DOC",
    default: true,
    sesha: true,
    template: {
      add_accounts: ["40", "41", "47"],
      remove_accounts: ["404", "408", "416", "418", "419"],
    },
  },
];

function MissingDocuments(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);

  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [journalListInit, setJournalListInit] = useState([]);
  const [journalListFiltered, setJournalListFiltered] = useState([]);

  const [mode, setMode] = useState(1);

  const [countOthers, setCountOthers] = useState(0);
  const [missingDocAmount, setMissingDocAmount] = useState();
  const [missingDocData, setMissingDocData] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingMissingDoc, setLoadingMissingDoc] = useState(true);
  const [error, setError] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [currentTemplate, setCurrentTemplate] = useState();
  const [excludedJournals, setExcludedJournals] = useState([]);

  const [showMailDrawer, setShowMailDrawer] = useState(false);
  const ref = useRef(0);

  const [displayReminderMail, setDisplayReminderMail] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    const res = await API.putClient(clientId, {
      missingDocReminderMail: null,
    });
    if (res.status === 201) {
      //openNotification("success", "La relance mail a bien été supprimée.");
      //console.log("La relance mail a bien été supprimée.");
      dispatch(
        updateClient({
          missingDocReminderMail: null,
        })
      );
      setIsModalOpen(false);
    } else {
      //openNotification("error", "Erreur lors de la suppression de la relance.");
      //console.log("Erreur lors de la suppression de la relance.");
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setAllExpanded(
      props.badgeMissing - client?.otherMissingDocuments?.length <
        process.env.REACT_APP_EXPAND_LIMIT
    );
  }, [missingDocData, props.badgeMissing, client.otherMissingDocuments]);

  useEffect(() => {
    if (!props.wait && !error && missingDocData) {
      let myOperations = missingDocData.operationCategories.filter(
        (opCat) =>
          opCat.operations.findIndex(
            (op) =>
              op.showToClient === true &&
              !op.comment &&
              !op.isLost &&
              !op.isLostNoAccount &&
              op.files.length === 0
          ) !== -1
      );
      if (myOperations.length > 0) {
        setDisplayReminderMail(true);
      } else {
        setDisplayReminderMail(false);
        dispatch(
          updateClient({
            missingDocReminderMail: null,
          })
        );
      }
    }
  }, [props.wait, client, props.refreshMissingDoc, missingDocData]);

  //set template and excluded journals
  useEffect(() => {
    console.debug("useEffect getAnalysisConfigTemplates");
    if (!props.wait);
    (async () => {
      if (!accountingFirm.proAbonoFeatures?.includes("analysis_config")) {
        setCurrentTemplate(defaultTemplate);
      } else {
        let res = await API.getAnalysisConfigTemplates(clientId);
        if (res.status === 200) {
          res = await res.json();
          const current = res.find((t) => t._id === client.templates?.missingDoc);
          setCurrentTemplate(current);
        } else {
          setCurrentTemplate(defaultTemplate);
        }
      }
      setExcludedJournals(currentTemplate?.template?.remove_journals?.sort() || []);
    })();
  }, [props.wait, client]);

  useEffect(() => {
    if (client.status === "ready") {
      setCountOthers(client.otherMissingDocuments?.length);
      setLoading(false);
    }
  }, [client.otherMissingDocuments]);

  useEffect(() => {
    console.debug("useEffect getMissingDocuments");
    setLoadingMissingDoc(true);
    if (!props.wait)
      (async () => {
        let res = await API.getMissingDocuments(clientId);
        if (res.status === 200) {
          res = await res.json();
          setError(false);
          if (accountingFirm.fec === true) {
            const missingDocDataWithoutGhost = {
              ...res,
              operationCategories: res.operationCategories.map((category) => ({
                ...category,
                operations: category.operations.filter((operation) => operation.ghost !== 1),
              })),
            };
            setMissingDocData(missingDocDataWithoutGhost);
          } else {
            setMissingDocData(res);
          }
        } else {
          setError(true);
          props.setBadgeMissing(0);
        }

        dispatch(
          updateClient({
            journalList: (
              (client.journalList && client.journalList.length > 0
                ? client.journalList
                : res?.journals?.missing_documents) || []
            ).filter((el) => !currentTemplate?.template?.remove_journals?.includes(el)),
          })
        );
        setJournalListInit(
          (
            (client.journalList && client.journalList.length > 0
              ? client.journalList
              : res?.journals?.missing_documents) || []
          ).filter((el) => !currentTemplate?.template?.remove_journals?.includes(el))
        );
        setLoadingMissingDoc(false);
      })();
  }, [props.wait, clientRemoteId, props.date, props.refreshMissingDoc, mode]);

  useEffect(() => {
    if (!props.wait && !error && missingDocData) {
      dispatch(
        updateClient({
          operationCategories: missingDocData.operationCategories,
          otherMissingDocuments: missingDocData.otherMissingDocuments,
        })
      );
    }
  }, [props.wait, clientRemoteId, props.date, missingDocData, journalListFiltered]);

  useEffect(() => {
    console.debug("useEffect setStartDate and setJournalListFiltered");
    if (!client.filterStartDate) {
      setStartDate(
        dayjs().subtract(15, "month").set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      );
    } else {
      setStartDate(dayjs(client.filterStartDate));
    }

    if (missingDocData) {
      if (client.journalList?.length > 0) {
        setJournalListFiltered([
          ...new Set(
            [...client.journalList.map((s) => s.trim()), ...journalListInit]
              .filter((el) => !client.hiddenJournals?.includes(el))
              .filter((el) => !currentTemplate?.template?.remove_journals?.includes(el))
          ),
        ]);
      } else {
        setJournalListFiltered(
          missingDocData.journals.missing_documents
            ?.map((s) => s.trim())
            .filter((el) => !currentTemplate?.template?.remove_journals?.includes(el))
        );
      }
    }
  }, [client.filterStartDate, client.journalList, missingDocData, journalListInit]);

  const others = [
    {
      key: "1",
      forceRender: true,
      label: (
        <>
          {" "}
          <div className="type-title">Autres justificatifs</div>
          <div className="type-separation"></div>
        </>
      ),
      children: (
        <div>
          <Other {...props} />
        </div>
      ),
    },
  ];

  const showDrawer = () => {
    setShowMailDrawer(true);
  };

  const onDrawerClose = () => {
    setShowMailDrawer(false);
  };

  useEffect(() => {
    if (props.selectedMailType === "") {
      ref.current = 0;
      props.onRefUpdate(ref);
    }
  }, [props.selectedMailType]);

  return (
    <>
      {editMode ? null : (
        <>
          <Affix offsetTop={120} className="btn-fix-missingdoc">
            <div className="site-drawer-render-missingdoc" onMouseDown={onDrawerClose}>
              <Button
                type="primary"
                onMouseEnter={showDrawer}
                className="select-indic-btn"
                disabled={props.badgeMissing === 0}
              >
                <MailOutlined style={{ fontSize: "22px" }} />
              </Button>
              <div
                onMouseLeave={onDrawerClose}
                onClick={(e) => {
                  e.preventDefault();
                  ref.current += 1;
                  props.onRefUpdate(ref);
                  //props.setSelectedMailType(props.type);
                  props.setSelectedMailType("missingdoc");
                }}
              >
                <Drawer
                  title="Demander les justificatifs au client"
                  rootClassName="indic-drawer missing-doc-drawer"
                  placement="right"
                  closable={false}
                  onClose={onDrawerClose}
                  open={showMailDrawer}
                  getContainer={false}
                  rootStyle={{ position: "absolute" }}
                  width={300}
                  mask={false}
                ></Drawer>
              </div>
            </div>
          </Affix>
          {client.email?.missingdoc?.lastEmailSent &&
          client.missingDocReminderMail?.sendDate &&
          client.missingDocReminderMail?.toSend &&
          displayReminderMail ? (
            <>
              <span className="mail-date-missingdoc-top">
                Dernier envoi {getMailDate(client.email.missingdoc.lastEmailSent)}
              </span>
              <span className="reminder-mail-date-missingdoc">
                <ReminderTrashBin onClick={showModal} className="reminder-delete-button" />
                Relance programmée {getMailDate(client.missingDocReminderMail.sendDate)}
              </span>
              <Modal
                title="Supprimer la relance mail programmée ?"
                className="modal-invalid"
                closable={false}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Supprimer"
                okButtonProps={{ className: "call-action-btn" }}
                cancelButtonProps={{ className: "other-action-btn invalid-cancel" }}
                cancelText="Annuler"
              >
                <p>
                  La relance est actuellement programmée pour{" "}
                  {getMailDate(client.missingDocReminderMail.sendDate)}.
                </p>
              </Modal>
            </>
          ) : client.email?.missingdoc?.lastEmailSent ? (
            <span className="mail-date-missingdoc">
              Dernier envoi {getMailDate(client.email.missingdoc.lastEmailSent)}
            </span>
          ) : null}
        </>
      )}
      <Header
        {...props}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        journalListInit={journalListInit}
        journalListFiltered={journalListFiltered}
        setJournalListFiltered={setJournalListFiltered}
        missingDocAmount={missingDocAmount}
        currentTemplate={currentTemplate}
        excludedJournals={excludedJournals}
        setAllExpanded={setAllExpanded}
      />
      {error ? (
        <>
          <Result
            icon={<CloseCircleOutlined style={{ color: "#4569f8" }} />}
            title="Erreur de chargement des pièces manquantes"
            subTitle={
              <>
                <p>Une erreur a été rencontrée lors de la génération des pièces manquantes.</p>
                <p>Cela peut être lié à trop gros volume de pièces ou aux journaux.</p>
                <p>
                  <b>Actualisez la page</b> pour faire un nouvel essai ou{" "}
                  <b>contactez le support.</b>
                </p>
              </>
            }
          />
        </>
      ) : (
        <>
          {loading ? (
            <>
              <Skeleton active={true} />
            </>
          ) : (
            <Collapse
              className="other-collapse"
              items={others}
              expandIconPosition="end"
              bordered={false}
              expandIcon={({ isActive }) => (
                <>
                  <UpCircleOutlined
                    style={{ fontSize: "26px" }}
                    className={isActive ? "expand-icon-down" : "expand-icon-up"}
                  />{" "}
                  <Tag color="#FF9700" style={{ marginLeft: "10px" }}>
                    {countOthers}
                  </Tag>
                </>
              )}
            />
          )}
          <Index
            {...props}
            missingDocData={missingDocData}
            setMissingDocData={setMissingDocData}
            startDate={startDate}
            endDate={endDate}
            journalListFiltered={journalListFiltered}
            allExpanded={allExpanded}
            setAllExpanded={setAllExpanded}
            setMissingDocAmount={setMissingDocAmount}
            error={error}
            mode={mode}
            setMode={setMode}
            loadingMissingDoc={loadingMissingDoc}
            setEditMode={setEditMode}
          />
        </>
      )}
    </>
  );
}

export default MissingDocuments;
